const replacer = [
    {
        token: /%\[woom\]%/g,
        replace: '<span class="woom-icon">woom</span>',
    },
    {
        token: /%\[([1-6])\]%/g,
        replace: '<span class="woom-icon number">$1</span>',
    },
    {
        token: /%\[mobile:(.*?)\]%/gi,
        replace: '<span class="lg:hidden">$1</span>',
    },
    {
        token: /%\[desktop:(.*?)\]%/gi,
        replace: '<span class="max-lg:hidden">$1</span>',
    },
    {
        token: /%\[(.*?)\]\((.*?)\)%/g,
        replace: '<a href="$2">$1</a>',
    },
    {
        token: /%(.*?) - (.*?)%/g, // %text - link%
        replace: '<a href="$2" target="_blank">$1</a>',
    },
    {
        token: /%\[redCharity\]%/g, // %[redCharity]%
        replace: '<span>(woom)<sup>RED</sup></span>',
    },
];

const woomTransformers: Record<string, (value: string) => string> = {
    '%[': (value: string) => {
        for (const rep of replacer) {
            value = value.replace(rep.token, rep.replace);
        }
        return value;
    },
    /**
     * book font
     */
    '%(': (value: string) => {
        return value.replace(/%\(/gi, '<span class="font-book">').replace(/\)%/gi, '</span>');
    },
    /**
     * bold font
     */
    '%{': (value: string) => {
        return value.replace(/%{/gi, '<span class="font-bold">').replace(/}%/gi, '</span>');
    },
};

export const hasWoomTransformer = (value: string) => {
    return Object.keys(woomTransformers).some((trigger) => value.includes(trigger));
};

export const woomTextFormat = (value: string, brakeAfterWoom = false) => {
    if (typeof value === 'string') {
        for (const transformKey in woomTransformers) {
            if (value.includes(transformKey)) value = woomTransformers[transformKey](value);
        }
        if (brakeAfterWoom) {
            value = value.replace(/<span class="woom-icon">woom<\/span>/, '<span class="woom-icon">woom</span><br/>');
        }
    }
    return value;
};

// Regular expression to identify HTML tags in the string. Replacing the identified HTML tag with a null string.
export const removeTags = (str: string) => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
};

/**
 * strips all woom text format
 * we need to manually infere type string
 * @param {string} value
 * @returns {string}
 */
export const removeWoomTextFormat = (value: string) => removeTags(woomTextFormat(value) as string) as string;

export const textToId = (text: string) => {
    return text
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-z0-9/]+/g, '-') // Replace non-alphanumeric characters and slashes with hyphens
        .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
};

export const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};
